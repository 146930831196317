import {
  MEDUSA_BACKEND_URL,
  queryClient,
  INTERCOM_APP_ID,
  ENVIRONMENT,
} from '@lib/config';
import { AccountProvider } from '@lib/context/account-context';
import { CartDropdownProvider } from '@lib/context/cart-dropdown-context';
import { StoreProvider } from '@lib/context/store-context';
import { Hydrate } from '@tanstack/react-query';
import { CartProvider, MedusaProvider } from 'medusa-react';
import { AppPropsWithLayout } from '../types/global';
import '../styles/globals.css';
import ProtectedRoutes from '../modules/protected-routes';
import { datadogRum } from '@datadog/browser-rum';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr, enUS } from 'date-fns/locale';
import { Suspense, useEffect, useState } from 'react';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';
import { FavoriteProvider } from '../lib/context/favorite-context';
import { ToastProvider } from '../lib/context/toaster-context';
import pjson from '../../package.json';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import SpeedInsights from '@modules/common/components/speed-insights';
import { LoginProvider } from '../lib/context/login-context';

datadogRum.init({
  applicationId: '11f6bfd0-905f-4391-8262-f8275996f508',
  clientToken: 'pub8a532379dcf19fc14fc26770afef1788',
  site: 'datadoghq.eu',
  service: 'catalog-store',
  env: ENVIRONMENT,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: pjson.version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  traceSampleRate: 100,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: [
    /https:\/\/.*\.startcatalog\.com/,
    /https:\/\/.*\.catalogdemo\.fr/,
    /https:\/\/.*\.lacompagniedumas\.com/,
    /https:\/\/.*\.babymoov\.com/,
    /https:\/\/.*\.rivedroite-paris\.com/,
    /https:\/\/.*\.fimm\.fr/,
    /https:\/\/.*\.manufactureh\.com/,
    /https:\/\/.*\.cutbyfred\.com/,
    /https:\/\/.*\.nogent3etoiles\.com/,
    /https:\/\/.*\.gardette\.fr/,
  ],
});

function App({
  Component,
  pageProps,
}: AppPropsWithLayout<{ dehydratedState?: unknown }>) {
  const getLayout = Component.getLayout ?? ((page) => page);

  const [locale, setLocale] = useState(fr);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === 'en') {
      setLocale(enUS);
      document.documentElement.lang = 'en';
    } else {
      setLocale(fr);
      document.documentElement.lang = 'fr';
    }
  }, [i18n.language]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <MedusaProvider
        baseUrl={MEDUSA_BACKEND_URL}
        queryClientProviderProps={{
          client: queryClient,
        }}
      >
        <Hydrate state={pageProps.dehydratedState}>
          <ToastProvider>
            <LoginProvider>
              <CartDropdownProvider>
                <FavoriteProvider>
                  <AccountProvider>
                    <ProtectedRoutes>
                      <CartProvider>
                        <StoreProvider>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={locale}
                          >
                            <IntercomProvider appId={INTERCOM_APP_ID}>
                              {getLayout(<Component {...pageProps} />)}
                              <VercelAnalytics />
                              <SpeedInsights />
                            </IntercomProvider>
                          </LocalizationProvider>
                        </StoreProvider>
                      </CartProvider>
                    </ProtectedRoutes>
                  </AccountProvider>
                </FavoriteProvider>
              </CartDropdownProvider>
            </LoginProvider>
          </ToastProvider>
        </Hydrate>
      </MedusaProvider>
    </Suspense>
  );
}
export default App;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend/cjs';
import { locizePlugin } from 'locize';
import { format as formatDate, isDate } from 'date-fns';
import { fr, enUS } from 'date-fns/locale';

const locales = { fr, en: enUS };

const isProduction = process.env.NODE_ENV === 'production';

const locizeOptions = {
  projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID,
  apiKey: process.env.NEXT_PUBLIC_LOCIZE_API_KEY,
  version: process.env.NEXT_PUBLIC_LOCIZE_VERSION,
  referenceLng: 'fr',
};

i18n
  .use(Backend)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: !isProduction,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (isDate(value)) {
          const locale = locales[lng];
          return formatDate(value, format, { locale });
        }
      },
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    saveMissing: !isProduction,
  });

export default i18n;

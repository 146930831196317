import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { MEDUSA_BACKEND_URL } from '../../lib/config';
import { useAccount } from '../../lib/context/account-context';
import { datadogRum } from '@datadog/browser-rum';
import { useLogin } from '../../lib/context/login-context';

const ProtectedRoutes = ({ children }) => {
  const { customer } = useAccount();
  const { isLoggedIn } = useLogin();
  const router = useRouter();

  const protectedRoutes = [
    'order',
    'orders',
    'products',
    'cart',
    'checkout',
    'account',
    'backorders',
    'favorite-lists',
  ];
  // const pathIsProtected = protectedRoutes.includes(router.pathname);
  const pathIsProtected = useMemo(
    () =>
      router.pathname === '/' ||
      protectedRoutes.some((r) => router.pathname.includes(r)),
    [router?.pathname],
  );

  useEffect(() => {
    if (!pathIsProtected) return;
    if (!isLoggedIn && isLoggedIn !== null) {
      const path = '/login';
      router.push(path);
    }
  }, [isLoggedIn, router?.pathname]);

  useEffect(() => {
    if (customer) {
      datadogRum.setUser({
        id: customer.id,
        name: customer.firstName + ' ' + customer.lastName,
        email: customer.originalEmail || customer.email,
        seller: MEDUSA_BACKEND_URL,
        customer_email: customer.originalEmail ? customer.email : undefined,
      });
    }
  }, [customer]);
  if (!customer?.hasAccount && pathIsProtected) {
    return <div></div>;
  }
  return children;
};
export default ProtectedRoutes;

import convert, { Length, Mass, Volume } from 'convert';
import { DB_LENGTH_UNIT, DB_MASS_UNIT, DB_VOLUME_UNIT } from '@lib/constants';
import {
  PricesFromApi,
  ProductFromApi,
  ProductOptionFromApi,
  VariantFromApi,
  VariantOptionFromApi,
} from '../../models/api/product-from-api';
import { IStoreProduct } from '../../models/view-models/product';
import {
  IProductOption,
  IVariantOption,
} from '../../models/view-models/product-option';
import {
  IStoreVariant,
  IVariantPrices,
} from '../../models/view-models/variant';
import { StoreSettings } from '@lib/hooks/use-settings';
import { OptionType } from 'src/types/global';

export const buildStoreProduct = (
  product: ProductFromApi,
  displaySettings: StoreSettings,
): IStoreProduct => {
  const options = product.options?.map((option) => buildOption(option)) ?? [];
  const variants =
    product.variants
      ?.map((variant: VariantFromApi) =>
        buildStoreVariant(
          variant,
          displaySettings,
          product.options,
          product.tags as { value: string }[],
        ),
      )
      .filter((v) => v.price >= 0)
      .sort((a, b) => {
        return a?.title?.localeCompare(b?.title, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      }) ?? [];

  const pricesSet = new Set<number>(
    variants?.map((variant) => Number(variant.price)) ?? [],
  );

  const price =
    pricesSet.size === 1
      ? variants[0].price
      : Math.min(...Array.from(pricesSet));

  const imageUrl =
    product.thumbnail || product.variants?.[0]?.thumbnail || '/placeholder.png';

  return {
    id: product.id,
    title: product.title,
    thumbnail:
      imageUrl === '/placeholder.png'
        ? imageUrl
        : buildImageUrl(imageUrl, displaySettings),
    images:
      product.images?.map((image) =>
        buildImageUrl(image.url, displaySettings),
      ) ?? [],
    description: product.description,
    variantCount: product.variants?.length ?? 0,
    hasRangePrice: pricesSet.size > 1,
    metadata: product.metadata,
    variants,
    options,
    price,
  };
};

export const buildStoreVariant = (
  variant: VariantFromApi,
  displaySettings: StoreSettings,
  productOptions?: ProductOptionFromApi[],
  productTags?: { value: string }[],
  forceIsPreorder?: boolean,
): IStoreVariant => {
  const displayUnit = (variant.metadata?.display_unit as string) || null;
  const storageUnit = (variant.metadata?.storage_unit as string) || null;
  const hasUnity = !!displayUnit && !!storageUnit;
  const limitedStockThreshold =
    typeof variant.metadata?.limited_stock_threshold === 'number'
      ? variant.metadata?.limited_stock_threshold
      : displaySettings.limitedStockThreshold;
  const outOfStockEnabled =
    typeof variant.metadata?.out_of_stock_enabled === 'boolean'
      ? variant.metadata?.out_of_stock_enabled
      : displaySettings.outOfStockEnabled;
  const disableAddToCartWhenNoStock =
    typeof variant.metadata?.disable_add_to_cart_when_no_stock === 'boolean'
      ? variant.metadata?.disable_add_to_cart_when_no_stock
      : displaySettings.disableAddToCartWhenNoStock;
  const stockLevelEnabled = getStockLevelEnabled(
    variant,
    displaySettings,
    forceIsPreorder,
  );
  const quantityLeftEnabled =
    typeof variant.metadata?.quantity_left_enabled === 'boolean'
      ? variant.metadata?.quantity_left_enabled
      : displaySettings.quantityLeftEnabled;
  const limitedStockEnabled =
    typeof variant.metadata?.limited_stock_enabled === 'boolean'
      ? variant.metadata?.limited_stock_enabled
      : displaySettings.limitedStockEnabled;

  if (!variant) return undefined;
  return {
    id: variant.id,
    productId: variant.product_id,
    title: variant.title,
    ean: variant.ean,
    img:
      buildImageUrl(variant.thumbnail, displaySettings) || '/placeholder.png',
    images:
      variant.images?.map((image) =>
        buildImageUrl(image.url, displaySettings),
      ) ?? [],
    ref: variant.sku,
    prices: variant.prices?.map(buildVariantPrices) || [],
    price: hasUnity
      ? convert(variant.calculated_price / 100, displayUnit as Length).to(
          storageUnit as Length,
        )
      : variant.calculated_price / 100,
    originalPrice: variant.original_price / 100,
    quantity: variant.inventory_quantity,
    quantityInProduction:
      +variant.metadata?.quantity_in_production || undefined,
    nextRestockDate: variant?.metadata?.next_restock_date
      ? new Date(variant?.metadata?.next_restock_date as string)
      : undefined,
    options:
      variant.options
        ?.map((o) => buildVariantOption(o, displaySettings, productOptions))
        .sort(({ title: a }, { title: b }) => {
          return a?.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        }) || null,
    metadata: variant.metadata,

    // Example for convert variant values
    height: variant.height
      ? convertLength(variant.height, displaySettings.lengthUnit)
      : undefined,
    weight: variant.weight
      ? convertMass(variant.weight, displaySettings.massUnit)
      : undefined,
    width: variant.width
      ? convertLength(variant.width, displaySettings.lengthUnit)
      : undefined,
    length: variant.length
      ? convertLength(variant.length, displaySettings.lengthUnit)
      : undefined,
    moq: +variant.metadata?.moq || 1,
    pcb: +variant.metadata?.pcb || 1,
    // take the value if true or false is passed ; if undefined or null, take the value from the variant
    isPreorder:
      typeof forceIsPreorder === 'boolean'
        ? forceIsPreorder
        : variant.metadata?.is_preorder === true,
    isOnDemand: variant.metadata?.is_on_demand === true,
    productHandle: variant.product?.handle,
    productTitle: variant.product?.title,
    productTags: (productTags || variant.product?.tags)?.map(
      (tag) => tag.value,
    ),
    product: variant.product
      ? buildStoreProduct(variant.product, displaySettings)
      : undefined,
    displayUnit,
    storageUnit,
    outOfStockEnabled,
    stockLevelEnabled,
    quantityLeftEnabled,
    limitedStockEnabled,
    limitedStockThreshold,
    disableAddToCartWhenNoStock,
    allowDecimals:
      !!variant.metadata.display_unit && !!variant.metadata.storage_unit,
  };
};

export function buildOption(option: ProductOptionFromApi): IProductOption {
  return {
    id: option.id,
    title: option.title,
  };
}

export const getQuantityForAddToCartPayload = ({
  withDecimals,
  quantity,
  displayUnit,
  storageUnit,
}: {
  withDecimals: boolean;
  quantity: number;
  displayUnit: Length;
  storageUnit: Length;
}) => {
  return withDecimals
    ? convert(quantity, displayUnit).to(storageUnit)
    : quantity;
};

export function buildVariantOption(
  option: VariantOptionFromApi,
  displaySettings: StoreSettings,
  productOptions?: ProductOptionFromApi[],
): IVariantOption {
  const po =
    option.option ||
    productOptions?.find(
      (productOption) => productOption.id === option.option_id,
    );
  return {
    id: option?.id,
    title: po?.title || null,
    value: buildOptionValue(option.value, po?.metadata, displaySettings),
    optionId: option.option_id,
  };
}

function buildVariantPrices(price: PricesFromApi): IVariantPrices {
  return {
    id: price.id,
    amount: price.amount / 100,
    currencyCode: price.currency_code,
    maxQuantity: price.max_quantity,
    minQuantity: price.min_quantity,
    priceListId: price.price_list_id,
    variantId: price.variant_id,
  };
}

function buildOptionValue(
  value: string,
  metadata: Record<string, unknown>,
  displaySettings: StoreSettings,
): string {
  return splitWithEscape(value, '|')
    ?.map((v) =>
      buildValueWithUnit(
        v,
        metadata?.option_type as OptionType,
        displaySettings,
      ),
    )
    .join(', ');
}

export function buildValueWithUnit(
  value: string,
  optionType: OptionType | undefined,
  displaySettings: StoreSettings,
): string {
  if (!displaySettings || !optionType) return value;
  switch (optionType) {
    case OptionType.LENGTH:
      return convertLength(+value, displaySettings.lengthUnit);
    case OptionType.MASS:
      return convertMass(+value, displaySettings.massUnit);
    case OptionType.VOLUME:
      return convertVolume(+value, displaySettings.volumeUnit);
    default:
      return value;
  }
}

function convertLength(v: number, displayUnit: Length): string {
  if (!displayUnit) return `${v} ${DB_LENGTH_UNIT}`;
  return `${roundAt(
    convert(v, DB_LENGTH_UNIT).to(displayUnit),
    2,
  )} ${displayUnit}`;
}

function convertMass(v: number, displayUnit: Mass): string {
  if (!displayUnit) return `${v} ${DB_LENGTH_UNIT}`;
  return `${roundAt(
    convert(v, DB_MASS_UNIT).to(displayUnit),
    2,
  )} ${displayUnit}`;
}

function convertVolume(v: number, displayUnit: Volume): string {
  if (!displayUnit) return `${v} ${DB_LENGTH_UNIT}`;

  return `${roundAt(
    convert(v, DB_VOLUME_UNIT).to(displayUnit),
    2,
  )} ${displayUnit}`;
}

function roundAt(num: number, precision: number): number {
  const factor = 10 ** precision;
  return Math.round(num * factor) / factor;
}

function buildImageUrl(url: string, settings: StoreSettings): string {
  if (!settings.useCompressedImages || !url) return url;
  const original = `https://catalog-${settings.sellerNameTrimLow}-`;
  const substitute = `https://catalog-${settings.sellerNameTrimLow}-compressed-`;
  return url.replace(original, substitute);
}

// This method is used to split a string by a separator, the separator ca be escaped by itself
// Example: splitWithEscape('a|b||c', ',') => ['a', 'b|c']
function splitWithEscape(str: string, separator: string): string[] {
  const r = String.fromCharCode(0x2014); // em dash will be used as a temporary replacement
  return str
    ?.replaceAll(`${separator}${separator}`, r)
    .split(separator)
    .map((s) => s.replaceAll(r, separator));
}

function getStockLevelEnabled(
  variant: VariantFromApi,
  displaySettings: StoreSettings,
  forceIsPreorder?: boolean,
) {
  if (forceIsPreorder === undefined && variant.metadata?.is_preorder) {
    return false;
  }
  return typeof variant.metadata?.stock_level_enabled === 'boolean'
    ? variant.metadata?.stock_level_enabled
    : displaySettings.stockLevelEnabled;
}

import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';
import { useCatalogTranslation } from '../hooks/use-catalog-translations';
import { useLoginForm } from '../hooks/use-login-form';
import { z } from 'zod';
import { useCustomerCompanyMutations } from '../hooks/use-customer-company';
import { useRegistrationForm } from '../hooks/use-registration-form';
import { useToasts } from './toaster-context';
import {
  authenticate,
  checkIfEmailExists,
  getCustomerAccount,
  requestPasswordReset,
} from '../data/customer-data';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

type LoginStep = 'login' | 'email' | 'password' | 'registration';

interface ILoginContext {
  loginPageContent: LoginContentType;
  loginImage: string;
  sellerName: string;
  loginHasFailed: boolean;
  hasRegistered: boolean;
  currentStep: LoginStep;
  inputEmail: string;
  isLoggedIn: boolean;
  registrationFormEnabled: boolean;
  onSubmit: (data: unknown) => Promise<void>;
  goToStep: (step: LoginStep) => void;
  handleBackClick: () => void;
}

type LoginContentType = {
  title: string;
  subTitle: string;
  formCta: string;
  infoCardTitle: string;
  infoCardText: string;
  infoCardCta: string;
  infoCardNewClientTitle: string;
  infoCardNewClientText: string;
  infoCardNewClientCta: string;
};

const LoginContext = createContext<ILoginContext | null>(null);

export const LoginProvider = ({ children }: { children: React.ReactNode }) => {
  const registrationFormEnabled =
    process.env.NEXT_PUBLIC_USE_REGISTRATION_FORM === 'true';
  const { registrationFormSchema, buildRegistrationPayload } =
    useRegistrationForm();
  const { loginSchema, sendEmailSchema, definePasswordSchema } = useLoginForm();
  const {
    updateCustmerPasswordMutation: update,
    createNewCustomerMutation: create,
  } = useCustomerCompanyMutations();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  type LoginDataType = z.infer<typeof loginSchema>;
  type SendEmailDataType = z.infer<typeof sendEmailSchema>;
  type DefinePasswordDataType = z.infer<typeof definePasswordSchema>;
  type RegistrationFormData = z.infer<typeof registrationFormSchema>;

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(null);
  const [currentStep, setCurrentStep] = useState<LoginStep>('login');
  const [inputEmail, setInputEmail] = useState('');
  const [hasRegistered, setHasRegistered] = useState(false);

  const [loginPageContent, setLoginPageContent] = useState<LoginContentType>({
    title: '',
    subTitle: '',
    formCta: '',
    infoCardTitle: '',
    infoCardText: '',
    infoCardCta: '',
    infoCardNewClientTitle: '',
    infoCardNewClientText: '',
    infoCardNewClientCta: '',
  });
  const [loginHasFailed, setLoginHasFailed] = useState(false);
  const loginImage = `${process.env.NEXT_PUBLIC_ASSETS_BUCKET_URL}/login_image.webp`;
  const sellerName = process.env.NEXT_PUBLIC_SELLER_NAME;
  const { ct } = useCatalogTranslation(['common']);
  const { i18n } = useTranslation();

  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (isLoggedIn === null) {
      const checkIfCustomerIsLogged = async () => {
        try {
          const customer = await getCustomerAccount();
          if (customer.hasAccount) {
            setIsLoggedIn(true);
          }
          return;
        } catch (error) {
          setIsLoggedIn(false);
          return;
        }
      };
      checkIfCustomerIsLogged();
    }
  }, []);

  useEffect(() => {
    const step = query.step as LoginStep;
    if (!step) {
      setCurrentStep('login');
    } else {
      setCurrentStep(step);
    }
  }, [query]);

  useEffect(() => {
    switch (currentStep) {
      case 'login': {
        setLoginPageContent({
          title: ct(
            'Login.title',
            "Connectez-vous à l'espace pro {{sellerName}}",
            {
              sellerName,
            },
          ),
          subTitle: ct(
            'Login.subTitleLoginForm',
            'Saisissez votre adresse e-mail et mot de passe associé pour vous connecter à l’espace pro {{sellerName}}',
            { sellerName },
          ),
          formCta: ct('Login.loginButton', 'Accéder à mon espace'),
          infoCardTitle: ct(
            'Login.infoCardfirstConnexionTitle',
            "Vous n'avez pas encore votre mot de passe pour vous connecter ?",
          ),
          infoCardText: ct(
            'Login.infoCardfirstConnexionDescription',
            "Si vous êtes deja client mais n'avez pas encore defini de mot de passe, définissez-le en quelques secondes",
          ),
          infoCardCta: ct(
            'Login.infoCardfirstConnexionButton',
            'Activer mon compte client',
          ),
          infoCardNewClientTitle: ct(
            'Login.infoCardBecomeCustomerTitle',
            'Nouveau client ?',
          ),
          infoCardNewClientText: ct(
            'Login.infoCardBecomeCustomerDescription',
            'Inscrivez-vous pour rejoindre les clients {{sellerName}} et accéder à leur catalogue produit',
            { sellerName },
          ),
          infoCardNewClientCta: ct(
            'Login.infoCardBecomeCustomerButton',
            'Devenir client',
          ),
        });
        break;
      }
      case 'password': {
        setLoginPageContent({
          title: ct(
            'Login.definePasswordTitle',
            'Définissez votre mot de passe',
          ),
          subTitle: ct(
            'Login.definePasswordSubTitle',
            'Votre mot de passe doit contenir au moins 8 caractères',
          ),
          formCta: ct('Login.definePasswordButton', 'Définir mon mot de passe'),
          infoCardTitle: '',
          infoCardText: '',
          infoCardCta: '',
          infoCardNewClientTitle: '',
          infoCardNewClientText: '',
          infoCardNewClientCta: '',
        });
        break;
      }
      case 'email': {
        setLoginPageContent({
          title: ct('Login.sendEmailTitle', 'Saisissez votre adresse mail'),
          subTitle: ct(
            'Login.sendEmailSubTitle',
            'Renseignez votre adresse email',
          ),
          formCta: ct('Login.sendEmailCta', 'Continuer'),
          infoCardTitle: ct(
            'Login.sendEmailInfoCardTitle',
            'Vous êtes déjà client ?',
          ),
          infoCardText: ct(
            'Login.sendEmailInfoCardText',
            'Vous recevrez un mail contenant un lien de connexion. Vous pourrez alors définir votre mot de passe et accéder à votre espace pro {{sellerName}}',
            { sellerName },
          ),
          infoCardCta: '',
          infoCardNewClientTitle: ct(
            'Login.sendEmailInfoCardNewClientTitle',
            'Nouveau client ?',
          ),
          infoCardNewClientText: ct(
            'Login.sendEmailInfoCardNewClientText',
            'Renseignez votre mail pro',
          ),
          infoCardNewClientCta: '',
        });
        break;
      }
      case 'registration': {
        setLoginPageContent({
          title: ct(
            'Login.registrationTitle',
            "Rejoignez l'espace pro {{ seller}}",
            { seller: sellerName },
          ),
          subTitle: ct(
            'Login.registrationSubTitle',
            'Bienvenue sur l’espace Pro {{ seller }}. Afin de consulter le catalogue et passer commande, remplissez ce formulaire.',
            { seller: sellerName },
          ),
          formCta: ct('Login.registrationButton', 'Accéder au catalogue'),
          infoCardTitle: '',
          infoCardText: '',
          infoCardCta: '',
          infoCardNewClientTitle: '',
          infoCardNewClientText: '',
          infoCardNewClientCta: '',
        });
        break;
      }
    }
  }, [currentStep, i18n.language]);

  type DefinePasswordData = DefinePasswordDataType & {
    email: string;
    token: string;
  };
  const onSubmit = async (
    data:
      | LoginDataType
      | SendEmailDataType
      | DefinePasswordData
      | RegistrationFormData,
  ) => {
    if (currentStep === 'login') {
      return await submitLogin(data as LoginDataType);
    }
    if (currentStep === 'email') {
      return await submitEmail(data as SendEmailDataType);
    }
    if (currentStep === 'password') {
      return await submitPassword(data as DefinePasswordData);
    }
    if (currentStep === 'registration') {
      return await submitRegistration(data as RegistrationFormData);
    }
  };

  const submitLogin = async (data: LoginDataType) => {
    const { email, password } = data;
    const customer = await authenticate({ email, password });
    queryClient.invalidateQueries(['account']);
    if (customer) {
      setIsLoggedIn(true);
      router.push('/');
    } else {
      setLoginHasFailed(true);
    }
  };

  const submitEmail = async (data: SendEmailDataType) => {
    const { email } = data;
    setInputEmail(email);
    try {
      const emailExists = await checkIfEmailExists(email);
      if (emailExists) {
        await requestPasswordReset(email);
        addToast({
          title: ct('Login.emailSentAlertTitle', 'Email envoyé'),
          variant: 'success',
          description: ct(
            'Login.emailSentAlertDesc',
            'Vous avez reçu un email à {{email}}. Consultez votre boite aux lettres et suivez les instructions pour vous connecter',
            { email },
          ),
        });
      } else if (registrationFormEnabled) {
        goToStep('registration');
      } else {
        addToast({
          title: ct('Login.emailSentAlertTitle', 'Email envoyé'),
          variant: 'success',
          description: ct('Login.emailSentAlertDesc', { email }),
        });
      }
    } catch {
      addToast({
        title: ct('Login.emailSentErrorTitle', "Une erreur s'est produite"),
        variant: 'error',
        description: ct(
          'Login.emailSentErrorDesc',
          "Erreur lors de l'envoi de l'email. Veuillez réessayer ou contacter le support à support@startcatalog.com",
        ),
      });
    }
  };

  const submitPassword = async (data: DefinePasswordData) => {
    const { password, token, email } = data;
    await update.mutateAsync(
      { password, email, token },
      {
        onSuccess: () => {
          addToast({
            title: ct(
              'Login.passwordChangedSuccessTitle',
              'Mot de passe modifié avec succès',
            ),
            variant: 'success',
            description: ct(
              'Login.passwordChangedSuccessDesc',
              'Vous pouvez maintenant vous connecter à votre espace {{sellerName}} en utilisant votre adresse {{email}} et votre nouveau mot de passe.',
              { email, sellerName },
            ),
          });
          router.push('/login');
        },
      },
    );
  };

  const submitRegistration = async (data: RegistrationFormData) => {
    const payload = buildRegistrationPayload(data, inputEmail);
    await create.mutateAsync(
      { payload },
      {
        onSuccess: async () => {
          setHasRegistered(true);
          return await requestPasswordReset(inputEmail);
        },
        onError: () => {
          const message = ct(
            'Registration.failureMessage',
            "Vérifiez vos informations et essayez de nouveau. Si l'erreur persiste, contactez le support à support@startcatalog.com",
          );
          const title = ct(
            'Registration.failure',
            "Oups, une erreur s'est produite",
          );
          addToast({
            description: message,
            variant: 'success',
            title,
          });
        },
      },
    );
  };

  const goToStep = (step: LoginStep) => {
    router.push({ query: { step } });
  };

  const handleBackClick = () => {
    router.back();
  };

  return (
    <LoginContext.Provider
      value={{
        loginPageContent,
        loginImage,
        sellerName,
        loginHasFailed,
        currentStep,
        onSubmit,
        goToStep,
        handleBackClick,
        hasRegistered,
        inputEmail,
        registrationFormEnabled,
        isLoggedIn,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error('useLogin must be used within a LoginProvider');
  }
  return context;
};
